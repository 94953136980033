import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Flex, Box } from '@rebass/grid'

import { StyledBody } from '../components/post/body'
import Navbar from '../components/Navbar'
import Footer from '../components/footer'

const maxWidth = 825
const padding = 95

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 52px;
  padding: 0px ${padding}px;
  color: #000000;
  margin-bottom: 24px;
`

const Resource = ({ data, pageContext }) => {
  const { wordpressPage: page } = data
  return (
    <Flex flexDirection="column" css={{ overflow: 'hidden' }}>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `${pageContext.autopilot}`,
          },
        ]}
      >
        <html lang={pageContext.lang} />
        <title>{page.yoast_meta.yoast_wpseo_title}</title>
        <meta
          name="description"
          content={page.yoast_meta.yoast_wpseo_metadesc}
        />
        <meta name="application-name" content="hubtype" />
        <meta name="apple-mobile-web-app-title" content="hubtype" />
      </Helmet>
      <Navbar py={[12, 12, 12]} pageContext={pageContext} />
      <Flex justifyContent="center" pt={136} style={{ background: 'white' }}>
        <Box width={1} style={{ maxWidth }}>
          <Title>{page.title}</Title>
          <StyledBody shareText={page.title} shareUrl={pageContext.absoluteUrl}>
            {page.content}
          </StyledBody>
        </Box>
      </Flex>
      <Footer pageContext={pageContext} />
    </Flex>
  )
}

export default Resource

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`
